<template>
    <div class="popup" :v-if="popupVisible">
        <div class="box step1Pop">
            <p class="popTitle">{{ $t("page.patient.list.button.regist") }}</p>
            <v-form ref="registForm" lazy-validation>
                <ul class="cont">
                    <li>
                        <p class="tit">{{ $t("page.patient.detail.subTitle.required") }}</p>
                        <table class="whiteTbl">
                            <caption>환자등록 기본정보</caption>
                            <colgroup>
                                <col style="width:100px"/>
                                <col />
                                <col style="width:100px"/>
                                <col />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>{{ $t("common.patient.sn") }}<span class="em">*</span></th>
                                    <td>
                                        <div class="patientSnRow">
                                            <Input 
                                                :label="''"
                                                :type="'text'"
                                                :name="'patientSn'"
                                                :required=true
                                                :width="72"
                                                :fnName="numericRule($t('common.patient.sn'))"
                                                :maxLength="11"
                                                v-model="formValues.patientSn"
                                            />
                                            <BtnCompo
                                                :width="'25%'"
                                                :height="'34px'"
                                                :txtBtn="buttonText"
                                                :fontSize="'14px'"
                                                :fontWeight="'500'"
                                                :borderRadius="'4px'"
                                                :marginBottom="'5px'"
                                                :onClick="() => {
                                                    checkPatientSn();
                                                }" 
                                            />
                                        </div>
                                    </td>
                                    <th>{{ $t("common.patient.name") }}<span class="em">*</span></th>
                                    <td>
                                        <Input 
                                            :label="''"
                                            :type="'text'"
                                            :name="'name'"
                                            :required=true
                                            :width="'fullWidth'"
                                            v-model="formValues.name"
                                            :fnName="notNullRules($t('common.patient.name'))"
                                            :value="initialValue"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>{{ $t("common.patient.birth") }}<span class="em">*</span></th>
                                    <td>
                                        <div class="inpSet">
                                            <label>
                                                <select v-model="selectedYear" @change="getBirth('year',$event)" >
                                                    <option v-for="(year, index) in years" :key="index" :value="year">{{ year }}</option>
                                                </select>
                                            </label>{{ $t("common.date.year") }}
                                            <label>
                                                <select v-model="selectedMonth" @change="getBirth('month',$event)">
                                                    <option v-for="(month, index) in months" :key="index" :value="month">{{ month }}</option>
                                                </select>
                                            </label>{{ $t("common.date.month") }}
                                            <label>
                                                <select v-model="selectedDay" @change="getBirth('day',$event)">
                                                    <option v-for="(day, index) in days" :key="index" :value="day">{{ day }}</option>
                                                </select>
                                            </label>{{ $t("common.date.day") }}
                                        </div>
                                    </td>
                                    <th>{{ $t("common.patient.sex") }}<span class="em">*</span></th>
                                    <td>
                                        <div class="inpSet">
                                            <v-radio-group row v-model="formValues.sex" density="compact" hide-details="auto">
                                                <v-radio :label="translateSex(0)" :value="0"></v-radio>
                                                <v-radio :label="translateSex(1)" :value="1"></v-radio>
                                            </v-radio-group>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </li>
                    <li>
                    <p class="tit">{{ $t("page.patient.detail.subTitle.optional") }}</p>
                    <table class="whiteTbl">
                        <caption>환자등록 추가정보</caption>
                        <colgroup>
                            <col style="width:100px"/>
                            <col />
                            <col style="width:100px"/>
                            <col />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>{{ $t("page.patient.detail.optional.weight") }}</th>
                                <td>
                                    <div class="spaceBetween">
                                        <Input 
                                            :label="''"
                                            :type="'number'"
                                            :name="'weight'"
                                            :required=false
                                            :width="90"
                                            :fnName="none()"
                                            v-model="formValues.weight"
                                        />
                                        <label>kg</label>
                                    </div>
                                 </td>
                                <th>{{ $t("page.patient.detail.optional.height") }}</th>
                                <td>
                                    <div class="spaceBetween">
                                        <Input 
                                            :label="''"
                                            :type="'number'"
                                            :name="'height'"
                                            :required=false
                                            :width="90"
                                            v-model="formValues.height"
                                            :fnName="none()"
                                        />
                                        <label>cm</label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>{{ $t("page.patient.detail.optional.fWeight") }}</th>
                                <td>
                                    <div class="spaceBetween">
                                        <Input 
                                            :label="''"
                                            :type="'number'"
                                            :name="'fatherWeight'"
                                            :required=false
                                            :width="90"
                                            v-model="formValues.fatherWeight"
                                            :fnName="none()"
                                        />
                                        <label>kg</label>
                                    </div>
                                </td>
                                <th>{{ $t("page.patient.detail.optional.fHeight") }}</th>
                                <td>
                                    <div class="spaceBetween">
                                        <Input 
                                            :label="''"
                                            :type="'number'"
                                            :name="'fatherHeight'"
                                            :required=false
                                            :width="90"
                                            v-model="formValues.fatherHeight"
                                            :fnName="none()"
                                        />
                                        <label>cm</label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>{{ $t("page.patient.detail.optional.mWeight") }}</th>
                                <td>
                                    <div class="spaceBetween">
                                        <Input 
                                            :label="''"
                                            :type="'number'"
                                            :name="'motherWeight'"
                                            :required=false
                                            :width="90"
                                            v-model="formValues.motherWeight"
                                            :fnName="none()"
                                        />
                                        <label>kg</label>
                                    </div>
                                </td>
                                <th>{{ $t("page.patient.detail.optional.mHeight") }}</th>
                                <td>
                                    <div class="spaceBetween">
                                        <Input 
                                            :label="''"
                                            :type="'number'"
                                            :name="'motherHeight'"
                                            :required=false
                                            :width="90"
                                            v-model="formValues.motherHeight"
                                            :fnName="none()"
                                        />
                                        <label>cm</label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>{{ $t("page.patient.detail.optional.note") }}</th>
                                <td colspan="3">
                                    <v-textarea flat solo density="compact" hide-details="auto" v-model="formValues.otherDetails"></v-textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </li>
            </ul>
            </v-form>
            <div class="btnW popBtn">
                <button class="btn default" @click="closePopup(false)" v-ripple>{{ $t('common.button.close') }}</button>
                <button class="btn solid" @click="registPatient()" v-ripple>{{ $t('common.button.reg') }}</button>
            </div>
        </div>
        <v-dialog v-model="openRegistPopup" width="320">
            <DefaultPopup
                :popupText="registMyPatientMsg"
                :secondTxt="popupButton"
                :firstBtn="true"
                :onClick="() => { registMyPatient(); }"
                @closePopup="openRegistPopup = false"
            />
        </v-dialog>
        <v-dialog v-model="openPopup" width="320">
            <DefaultPopup
                :popupText="popupMsgText"
                :secondTxt="popupButton"
                :firstBtn="true"
                :onClick="() => {  registProgress();  }"
                @closePopup="openPopup = false"
            />
        </v-dialog>
    </div>
</template>

<script>
import { rules } from '../mixin/rules'
import API_REGIST_PATIENT from '../API/patient/regist'
import API_REGIST_MY_PATIENT from '../API/patient/registMyPatient'
import API_CHECK_PATIENT_SN from '../API/patient/chkPatientSn'
import EventBus from '../plugins/EventBus'

export default {
    components: {
    },
    mixins: [rules],
    props: {
        popupVisible: {
            default: false
        },
        onClick: {
            default: () => { }
        },
        initialValues: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            formValues: { ...this.initialValues },
            years: [],
            months: [],
            days: [],
            selectedYear: '',
            selectedMonth: '01',
            selectedDay: '01',
            initialValue: '',
            openPopup: false,
            targetInfo: {},
            isChecked: false,
            registMyPatientMsg: '',
            openRegistPopup: false,
            apiFlag: false,
            pdxLang: localStorage.getItem('pdx-lang')
        }
    },
    watch: {},
    created() {},
    mounted() {
        this.setDate()
    },
    destroyed() {
    },
    computed: {
        currentRouteName() {
            return this.$route.name;
        },
        buttonText(){
            return this.$t('page.patient.detail.button.check');
        },
        popupMsgText(){
            return this.$t('page.patient.detail.msg.regist');
        },
        popupButton(){
            return this.$t('common.button.ok')
        }
    },
    methods: {
        resetForm() {
            this.formValues = {
                name: '',
                patientSn: '',
                birth: '',
                sex: 0,
                weight: '',
                height: '',
                fatherWeight: '',
                fatherHeight: '',
                motherWeight: '',
                motherHeight: '',
                otherDetails: '',
                crtId: ''
            }
        },
        closePopup(progress) {
            this.resetForm();
            this.$emit('closePopup',progress);
            this.$refs.registForm.resetValidation();
            this.isChecked = false;
        },
        setDate() {
            let dt = new Date();
            let year = dt.getFullYear();
            let startYear = 1940;
            for (let y = startYear; y <= year; y++) {
                this.selectedYear = year - 20
                this.years.push(y)
            }
            for (let m = 1; m <= 12; m++) {
                if (m < 10) m = "0" + m
                this.months.push(m)
            }
            for (let d = 1; d <= 31; d++) {
                if (d < 10) d = "0" + d
                this.days.push(d)
            }
        },
        getBirth(type, event) {
            switch (type) {
                case 'year':
                    this.selectedYear = event.target.value;
                    break;
                case 'month':
                    this.selectedMonth = event.target.value;
                    break;
                case 'day':
                    this.selectedDay = event.target.value;
                    break;
            }
        },
        registPatient() {
            if(!this.validate()) return false;
            const validate = this.$refs.registForm.validate();
            if (validate) {
                if (!this.isChecked) {
                    this.showPopup(this.$t('page.patient.detail.msg.error.checkDupl'));
                } else {
                    this.openPopup = true;    
                }
            }
        },
        async registProgress() {
            this.formValues.birth = this.selectedYear + "-" + this.selectedMonth + "-" + this.selectedDay;
            let res = await API_REGIST_PATIENT.request(this.formValues)
            if (res.isSuccess) { // 성공
                this.openPopup = false;
                this.closePopup(true);
            } else { // 실패
                this.openPopup = false;
                this.showPopup(res.errorMsg, res.status);
            }
        },
        validate(){
            // if(!this.lengthCheck('체중은',this.formValues.weight)) return false;
            // if(!this.lengthCheck('키는',this.formValues.height)) return false;
            // if(!this.lengthCheck('부 체중은',this.formValues.fatherWeight)) return false;
            // if(!this.lengthCheck('부 키는',this.formValues.fatherHeight)) return false;
            // if(!this.lengthCheck('모 체중은',this.formValues.motherWeight)) return false;
            // if(!this.lengthCheck('모 키는',this.formValues.motherHeight)) return false;
            if(!this.lengthCheck(this.$t('page.patient.detail.optional.weight')+this.$t('common.text.is1'),this.formValues.weight)) return false;
            if(!this.lengthCheck(this.$t('page.patient.detail.optional.height')+this.$t('common.text.is2'),this.formValues.height)) return false;
            if(!this.lengthCheck(this.$t('page.patient.detail.optional.fWeight')+this.$t('common.text.is1'),this.formValues.fatherWeight)) return false;
            if(!this.lengthCheck(this.$t('page.patient.detail.optional.fHeight')+this.$t('common.text.is2'),this.formValues.fatherWeight)) return false;
            if(!this.lengthCheck(this.$t('page.patient.detail.optional.mWeight')+this.$t('common.text.is1'),this.formValues.fatherWeight)) return false;
            if(!this.lengthCheck(this.$t('page.patient.detail.optional.mHeight')+this.$t('common.text.is2'),this.formValues.fatherWeight)) return false;
            return true;
        },
        lengthCheck(text, value) {
            if(value.length < 1) return true;
            const regex = /^[0-9]{0,3}(\.[0-9]{0,2})?$/;
            if (!regex.test(value)) {
                  this.openPopup = false;
                this.showPopup(text+this.$t('page.patient.detail.msg.error.checkLength'));
                return false;
            }
            return true;
        },
        async checkPatientSn() {
            let patientSn = this.formValues.patientSn;
            if (patientSn) {
                let res = await API_CHECK_PATIENT_SN.request(patientSn)
                if (res.isSuccess) {
                    if (res.result) {
                        this.targetInfo = res.result;
                        this.showPopup(this.$t('page.patient.detail.msg.error.exist'))
                    } else {
                        this.showPopup(this.$t('page.patient.detail.msg.check'));
                        this.isChecked = true;
                    }
                }else{
                    this.showPopup(res.errorMsg, res.status);
                }
            } else {
                this.showPopup(this.$t('page.patient.detail.msg.error.patientSn'));
            }
        },
        async registMyPatient() {
            if(this.apiFlag) return;
            this.apiFlag = true;
            let res = await API_REGIST_MY_PATIENT.request(this.targetInfo.no);
            this.apiFlag = false;
            if (res.isSuccess) {
                this.openRegistPopup = false;
                this.closePopup(true);
                if (this.currentRouteName === 'step1') {
                    localStorage.setItem('targetInfo', JSON.stringify(this.targetInfo));
                    EventBus.emit('change_target');
                    this.$router.push({ name: 'step2' });
                } else {
                    this.$router.push({
                        name: 'patientDetail',
                        params: { no: this.targetInfo.no }
                    })
                }
            } else {
                this.showPopup(res.errorMsg, res.status);
            }  
        }
    },
};
</script>

<style lang="scss" scoped>
@import "@/sass/app.scss";

.v-input--selection-controls{margin-top:0; padding-top:0;}
.spaceBetween{
    display: flex; 
    justify-content: space-between; 
    align-items: center;
    .input-wrapper{
        margin-bottom: 0;
        ::v-deep .v-text-field__slot {
            input {
                text-align: right;
            }
        }
        
    }
   
}

.patientSnRow{
    display: flex; 
    justify-content: space-between; 
    align-items: flex-start;
}
 ::v-deep .v-input__slot{
    padding:0 !important;
    margin-bottom:5px;
    
}
::v-deep .v-text-field__details{
    padding:0;
    margin-bottom:0;
}

::v-deep .v-text-field__slot {
    margin-right: 0 !important;
    textarea{
        margin-top: 0 !important;
        padding: 5px !important;
    }
}

::v-deep .primary--text{
    color:var(--color-point) !important;
    caret-color:var(--color-point) !important;
}

::v-deep .lay-root.thema-default{
    background-color: var(--color-point) !important;
    transition:box-shadow 0.5s,opacity 0.5s;
    &:hover{
        opacity: 0.85; box-shadow:0 4px 7px rgba(0,0,0,0.1);
    }
}
</style>